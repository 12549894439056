import { STAFF_USER_ROLE } from '../../environment';
import { Token } from '../../user/user.slice';
import { camelCasify } from '../../utils/text-formatting.utils';

export type RiPortPrivileges = {
  land: boolean;
  coastal: boolean;
  cruise: boolean;
};

export type Privileges = {
  canAccessIncidents: boolean;
  canAccessPorts: {
    wpi: boolean;
    riskIntelligence: false | RiPortPrivileges;
  };
  canAccessRiCountries: boolean;
  canAccessTravelAdvice: boolean;
  canAccessRoutes: boolean;
  canAccessHistory: boolean;
  canAccessBoundaries: boolean;
  canAccessDrawings: boolean;
  canAccessDocuments: boolean;
  canAccessUserPreferences: boolean;
  canAccessMaps: boolean;
  canAccessSanctions: boolean;
  canAccessRiMaritimeAreas: boolean;
  canAccessTimeline: boolean;
  canAccessMapTools: boolean;
  canAccessGraphs: boolean;
  canAccessNearbyVessels: boolean;
  canAccessWeather: boolean;
  canAccessShippingLanes: boolean;
  canMultiSelect: boolean;
  canAccessIncidentsDateRangeIndicator: boolean;
  canAccessBathymetry: boolean;
  canAccessDatumRings: boolean;
  canUploadRTZ: boolean;
  canDrawPoints: boolean;
  canAccessPortHeadline: boolean;
  canAccessHistoryWebSocket: boolean;
  canAccessHighTierHistoryWS: boolean;
  canAccessVPS: boolean;
};

const NO_PRIVILEGES: Privileges = {
  canAccessIncidents: false,
  canAccessPorts: {
    wpi: false,
    riskIntelligence: false,
  },
  canAccessRiCountries: false,
  canAccessTravelAdvice: false,
  canAccessRoutes: false,
  canAccessHistory: false,
  canAccessBoundaries: false,
  canAccessDrawings: false,
  canAccessDocuments: false,
  canAccessUserPreferences: false,
  canAccessMaps: false,
  canAccessSanctions: false,
  canAccessRiMaritimeAreas: false,
  canAccessTimeline: false,
  canAccessMapTools: false,
  canAccessGraphs: false,
  canAccessNearbyVessels: false,
  canAccessWeather: false,
  canAccessShippingLanes: false,
  canMultiSelect: false,
  canAccessIncidentsDateRangeIndicator: false,
  canAccessBathymetry: false,
  canAccessDatumRings: false,
  canUploadRTZ: false,
  canDrawPoints: false,
  canAccessPortHeadline: false,
  canAccessHistoryWebSocket: false,
  canAccessHighTierHistoryWS: false,
  canAccessVPS: false,
};

const UNAUTHENTICATED_USER_PRIVILEGES: Privileges = {
  ...NO_PRIVILEGES,
  canAccessPorts: {
    ...NO_PRIVILEGES.canAccessPorts,
    wpi: true,
  },
  canAccessSanctions: true,
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const authenticatedUserPrivileges = (userToken: Token): Privileges => ({
  ...NO_PRIVILEGES,
  canAccessIncidents: true,
  // Todo - group name defined by env var?
  // userToken.groups?.includes('staff') || false,
  canAccessPorts: {
    wpi: true,
    riskIntelligence: {
      land: true,
      coastal: true,
      cruise: true,
    },
  },
  canAccessRiCountries: true,
  canAccessTravelAdvice: true,
  canAccessRoutes: true,
  canAccessHistory: userToken.roles?.includes(STAFF_USER_ROLE) || false,
  canAccessBoundaries: true,
  canAccessDrawings: true,
  canAccessDocuments: true,
  canAccessUserPreferences: true,
  canAccessMaps: true,
  canAccessSanctions: true,
  canAccessRiMaritimeAreas: true,
  canAccessTimeline: true,
  canAccessMapTools: true,
  canAccessGraphs: true,
  canAccessNearbyVessels: userToken.roles?.includes(STAFF_USER_ROLE) || false,
  canAccessWeather: true,
  canAccessShippingLanes: userToken.roles?.includes(STAFF_USER_ROLE) || false,
  canMultiSelect: true,
  canAccessBathymetry: true,
  canUploadRTZ: true,
  canDrawPoints: true,
  canAccessPortHeadline: true,
  canAccessHistoryWebSocket: true,
  canAccessHighTierHistoryWS: true,
  canAccessVPS: true,
});

export const calculatePrivileges = (userToken: Token | null) => {
  // Todo - handle unauthenticated user privileges
  if (!userToken) return UNAUTHENTICATED_USER_PRIVILEGES;

  // uses environment variables to override default privileges.
  // camelcasifies the environment variable name and checks if it's a boolean
  // eg) REACT_APP_CAN_ACCESS_INCIDENTS=false overrides canAccessIncidents: true
  const environmentPrivilegeOverrides = Object.entries(process.env)
    .filter(([key]) => key.startsWith('REACT_APP_'))
    .map(([key, value]) => [camelCasify(key.replace('REACT_APP_', '')), value])
    .reduce((acc, [key, value]) => {
      if (value?.toLowerCase() === 'true' || value?.toLowerCase() === 'false') {
        acc[key! as keyof Privileges] = value === 'true';
      }
      return acc;
    }, {} as Record<string, boolean>);

  return {
    ...authenticatedUserPrivileges(userToken),
    ...environmentPrivilegeOverrides,
  };
};
