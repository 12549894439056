import { UserPreferences } from '../user-settings/user-preferences/user-preferences.slice';
import { wrapRequest } from './base';

export const getUserProperties = async (
  cognitoId: string
): Promise<{ user_preferences: UserPreferences }> =>
  wrapRequest('get', 'north_standard', `/user-properties`, {
    queryStringParameters: {
      cognito_id: cognitoId,
    },
  });

export const updateUserPreferences = async (
  cognitoId: string,
  preferences: UserPreferences
) => {
  wrapRequest('put', 'north_standard', `/user-properties`, {
    body: {
      cognito_id: cognitoId,
      user_config: {
        ...preferences,
      },
    },
  });
};
