import { useEffect, useState } from 'react';
import { setDocuments } from '../maritime-menu-options/documents-panel/documents.slice';
import { setAreas } from '../maritime-menu-options/ri-maritime-areas-panel/ri-maritime-areas.slice';
import { MaritimeArea } from '../models/risk_intelligence.model';
import { setBoundaries } from '../state/boundaries/boundaries.slice';
import { setCorrespondents } from '../state/correspondents/correspondents.slice';
import { setCountries } from '../state/countries/countries.slice';
import { setDrawings } from '../state/drawings/drawings.slice';
import { setSearchIncidents } from '../state/incidents/incidents.slice';
import {
  setError as setFirstCallError,
  setFirstCallPorts,
} from '../state/ports/first-call.slice';
import { setPorts } from '../state/ports/ports.slice';
import store from '../store';
import { getBoundaries } from './boundaries';
import { getS3DocsWithMetadata } from './documents';
import { getDrawings } from './drawings';
import { fetchNSCorrespondents } from './north-standard/correspondents';
import { fetchFirstCallPorts } from './north-standard/first-call';
import { getCountries } from './risk-intelligence/countries';
import { getIncidents, getMaritimeAreas } from './risk-intelligence/incidents';
import { getMergedPorts } from './risk-intelligence/ports';

export * from './risk-intelligence/countries';
export * from './risk-intelligence/incidents';
export * from './risk-intelligence/ports';
export * from './risk-intelligence/threat-assessments';

export * from './documents';
export * from './routes';
export * from './vessels';

export * from './boundaries';
export * from './drawings';
export * from './mapbox';

export * from './sanctions';

export * from './user-preferences';

export enum ReduxStatesToVerify {
  INCIDENTS = 'incidents',
  PORTS = 'ports',
  COUNTRIES = 'countries',
  DRAWINGS = 'drawings',
  BOUNDARIES = 'boundaries',
  DOCUMENTS = 'documents',
  MARITIME_AREAS = 'maritimeAreas',
  FIRST_CALL_PORTS = 'firstCallPorts',
  CORRESPONDENTS = 'correspondents',
}

interface LoadingOptions {
  before?: () => void;
  after?: () => void;
}

// This function is used to ensure that the redux store is populated with the data needed for state-restoring
// or application-wide search.
// example:
//    await ensureReduxLoaded(['incidents', 'vessels', 'ports'])
//    // do stuff that needs those states
// WIP: This function is not complete. The other branches of the switch statement need to be implemented.
export const useEnsureReduxLoaded = (
  statesToVerify: ReduxStatesToVerify[],
  options?: LoadingOptions
) => {
  const { before, after } = options || {};
  const reduxState = store.getState();
  const [loadingCompleteState, setLoadingCompleteState] = useState<
    Record<Partial<ReduxStatesToVerify>, boolean>
  >(
    statesToVerify.reduce((acc, state) => {
      acc[state] = false;
      return acc;
    }, {} as Record<Partial<ReduxStatesToVerify>, boolean>)
  );

  const handleLoadingComplete = (state: ReduxStatesToVerify) => {
    setLoadingCompleteState((prevState) => ({
      ...prevState,
      [state]: true,
    }));
  };

  useEffect(() => {
    before?.();
    statesToVerify.forEach((state) => {
      switch (state) {
        case ReduxStatesToVerify.INCIDENTS: {
          const { searchIncidents } = reduxState.incidents;
          if (!searchIncidents) {
            // getIncidents with no filters
            getIncidents({ ignoreAllFilters: true }).then((incidents) => {
              store.dispatch(setSearchIncidents(incidents));
              handleLoadingComplete(state);
              return incidents;
            });
            break;
          }
          // Incidents already loaded, simply return them
          handleLoadingComplete(state);
          break;
        }
        case ReduxStatesToVerify.PORTS: {
          if (!reduxState.ports.ports || reduxState.ports.ports.length === 0) {
            getMergedPorts(reduxState.user.idToken).then((ports) => {
              store.dispatch(setPorts(ports));
              handleLoadingComplete(state);
              return ports;
            });
            break;
          }
          handleLoadingComplete(state);
          break;
        }
        case ReduxStatesToVerify.COUNTRIES: {
          if (!reduxState.countries.countries) {
            getCountries().then((countries) => {
              store.dispatch(setCountries(countries));
              handleLoadingComplete(state);
              return countries;
            });
            break;
          }
          handleLoadingComplete(state);
          break;
        }
        case ReduxStatesToVerify.DRAWINGS: {
          if (
            !reduxState.drawings.drawings ||
            reduxState.drawings.drawings.length === 0
          ) {
            getDrawings().then((drawings) => {
              store.dispatch(setDrawings(drawings));
              handleLoadingComplete(state);
              return drawings;
            });
            break;
          }
          handleLoadingComplete(state);
          break;
        }
        case ReduxStatesToVerify.BOUNDARIES: {
          if (!reduxState.boundaries.boundaries) {
            getBoundaries()
              .then((boundaries) => {
                store.dispatch(setBoundaries(boundaries));
                handleLoadingComplete(state);
                return boundaries;
              })
              .catch(() => {
                handleLoadingComplete(state);
              });
            break;
          }
          handleLoadingComplete(state);
          break;
        }
        case ReduxStatesToVerify.DOCUMENTS: {
          // Refresh documents every time, in case a user was last looking
          // at a specific entity (and as such the documents would be filtered)
          getS3DocsWithMetadata().then(({ myDocuments }) => {
            store.dispatch(
              setDocuments({
                myDocuments,
              })
            );
            handleLoadingComplete(state);
          });
          break;
        }
        case ReduxStatesToVerify.MARITIME_AREAS: {
          if (!reduxState.riMaritimeAreas.areas) {
            getMaritimeAreas()
              .then((areas: MaritimeArea[]) => {
                store.dispatch(setAreas(areas));
                handleLoadingComplete(state);
                return areas;
              })
              .catch(() => handleLoadingComplete(state));
          }
          handleLoadingComplete(state);
          break;
        }
        case ReduxStatesToVerify.FIRST_CALL_PORTS: {
          if (!reduxState.firstCallPorts.firstCallPorts) {
            // getFirstCallPorts with no filters
            fetchFirstCallPorts()
              .then((portsCollection) => {
                store.dispatch(setFirstCallPorts(portsCollection.features));
                handleLoadingComplete(state);
                return portsCollection;
              })
              .catch(() => {
                handleLoadingComplete(state);
                store.dispatch(setFirstCallError(true));
              });
            break;
          }
          handleLoadingComplete(state);
          break;
        }
        case ReduxStatesToVerify.CORRESPONDENTS: {
          if (!reduxState.correspondents.correspondents) {
            fetchNSCorrespondents()
              .then((response) => {
                // sort the correspondents by name at the highest possible level
                response.data.sort((a, b) =>
                  a.port_information.name.localeCompare(b.port_information.name)
                );
                store.dispatch(setCorrespondents(response.data));
                handleLoadingComplete(state);
                return response.data;
              })
              .catch(() => handleLoadingComplete(state));
            break;
          }
          handleLoadingComplete(state);
          break;
        }
        default:
          throw new Error(`Unknown redux to check for: ${state}`);
      }
    });
  }, []);

  useEffect(() => {
    if (Object.values(loadingCompleteState).every((value) => value)) {
      after?.();
    }
  }, [loadingCompleteState]);

  return loadingCompleteState;
};
