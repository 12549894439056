import { Country, ExpandedCountry } from '../../models/risk_intelligence.model';
import { wrapRequest } from '../base';

export const getCountries = async (): Promise<Country[]> =>
  wrapRequest('get', 'north_standard', '/risk-intelligence/countries');

export const getCountry = async (
  countryId: string | number
): Promise<ExpandedCountry> =>
  wrapRequest(
    'get',
    'north_standard',
    `/risk-intelligence/countries/${countryId}`
  );
